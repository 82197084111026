const makeRoute = (base: string, path: string) => `${base}${path}`

const basePaths = {
	school: '/school',
	students: '/students',
	events: '/events',
	admissions: '/school/admissions',
	teachers: '/school/teachers',
	files: '/files',
	notifications: '/notifications',
	expenses: '/school/expenses',
	sessions: '/sessions',
	settings: '/settings',
}

const sessionPaths = {
	sessions: basePaths.sessions,
}
const expensePaths = {
	list: basePaths.expenses,
	create: basePaths.expenses,
	reset: makeRoute(basePaths.expenses, '/reset'),
	createMany: makeRoute(basePaths.expenses, '/insertMany'),
	getExpense: (id: string) => makeRoute(basePaths.expenses, `/${id}`),
	update: (id: string) => makeRoute(basePaths.expenses, `/${id}`),
	delete: (id: string) => makeRoute(basePaths.expenses, `/${id}`),
	uploadReceipt: (id: string) => makeRoute(basePaths.expenses, `/upload/${id}`),
}
const notificationPaths = {
	list: basePaths.notifications,
	create: basePaths.notifications,
	getById: (id: string) => makeRoute(basePaths.notifications, `/${id}`),
	delete: makeRoute(basePaths.notifications, '/delete'),
	delById: (id: string) => makeRoute(basePaths.notifications, `/${id}`),
}
const filePaths = {
	list: makeRoute(basePaths.files, '/file'),
	download: (id: string) => makeRoute(basePaths.files, `/download/${id}`),
	upload: makeRoute(basePaths.files, '/file/upload'),
	delete: (id: string) => makeRoute(basePaths.files, `/file/${id}`),
	multerUploads: makeRoute(basePaths.files, '/multer/upload'),
	getLocalFiles: makeRoute(basePaths.files, '/multer'),
	multerDownload: (path: string) =>
		makeRoute(basePaths.files, `/multer/download/${path}`),
	multerDelete: (path: string) => makeRoute(basePaths.files, `/multer/${path}`),
	uploadDoc: makeRoute(basePaths.files, '/multer/docs'),
}

const teacherRoutes = {
	list: basePaths.teachers,
	register: basePaths.teachers,
	detail: (id: string) => makeRoute(basePaths.teachers, `/${id}`),
	seed: makeRoute(basePaths.teachers, '/seed'),
	reset: makeRoute(basePaths.teachers, '/reset'),
}

const userPaths = {
	login: makeRoute('/users', '/login'),
	currentUser: makeRoute('/users', '/currentUser'),
	logout: makeRoute('/users', '/logout'),
	getUser: (id: string) => makeRoute('/users', `/${id}`),
	staus: makeRoute('/users', '/status'),
}

const baseUrl = {
	url: 'https://api.hps-admin.com/api',
	dev: 'https://4000-darkn3bula2-gitcontaine-qsz2q4nvk0n.ws-us108.gitpod.io/api',
	sockets: 'https://api.hps-admin.com',
}

const schoolPaths = {
	students: makeRoute(basePaths.school, '/students'),
	events: makeRoute(basePaths.school, '/events'),
	admissions: makeRoute(basePaths.school, '/admissions'),
}

const studentRoutes = {
	register: makeRoute(basePaths.school, '/students'),
	list: makeRoute(basePaths.school, '/students'),
	detail: makeRoute(basePaths.school, '/students/detail'),
	seed: makeRoute(basePaths.school, '/students/seed'),
	reset: makeRoute(basePaths.school, '/students/reset'),
	getDetail: (id: string) => makeRoute(basePaths.school, `/students/${id}`),
	byClass: (classId: string) =>
		makeRoute(basePaths.school, `/students/by-class/${classId}`),
}
const issuePaths = {
	list: makeRoute(basePaths.school, '/issues'),
	detail: makeRoute(basePaths.school, '/issues/detail'),
	edit: makeRoute(basePaths.school, '/issues/edit'),
	post: makeRoute(basePaths.school, '/issues'),
	reply: makeRoute(basePaths.school, '/issues/reply'),
	delete: makeRoute(basePaths.school, `/issues/delete`),
	delById: (id: string) => makeRoute(basePaths.school, `/issues/${id}`),
}
const eventPaths = {
	list: makeRoute(basePaths.school, '/events/list'),
	detail: makeRoute(basePaths.school, '/events/detail'),
	edit: makeRoute(basePaths.school, '/events/edit'),
}
export const map = {
	basePaths,
	baseUrl,
	userPaths,
	schoolPaths,
	makeRoute,
	user: userPaths,
	school: schoolPaths,
	students: studentRoutes,
	events: eventPaths,
	issues: issuePaths,
	teachers: teacherRoutes,
	files: filePaths,
	notifications: notificationPaths,
	expenses: expensePaths,
	session: sessionPaths,
} as const

export const alt =
	'https://4000-darkn3bula2-gitcontaine-qsz2q4nvk0n.ws-us108.gitpod.io/api'

export const key = 'haGv9z3ZNTwBfHBszfOjeu8q3ZfARGcN'

export const baseURL = import.meta.env.VITE_BASE_URL || map.baseUrl.url

export const httpConfig = {
	baseURL,
	headers: {
		'x-api-key': 'haGv9z3ZNTwBfHBszfOjeu8q3ZfARGcN',
		'Content-Type': 'application/json',
	},
	withCredentials: true,
}
