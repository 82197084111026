import { type ElementRef, type ReactNode, forwardRef } from 'react'

import { useToastStore } from '@/context/toastContext'
import { cn } from '@/lib/utils/cn'
import * as RadixToast from '@radix-ui/react-toast'
import { AnimatePresence, motion } from 'framer-motion'

import Icons from '@/components//icons/collection/basic'

export const Toast = forwardRef<
	ElementRef<typeof RadixToast.Root>,
	{
		onClose: () => void
		text: string
		type: 'success' | 'warning' | 'info' | 'error'
	}
>(function Toast({ onClose, text, type }, forwardedRef) {
	const width = 320
	const margin = 16

	const getToastStyles = () => {
		switch (type) {
			case 'success':
				return 'bg-brand/40 border-green-600'
			case 'warning':
				return 'bg-yellow-400/40 border-yellow-400'
			case 'info':
				return 'bg-blue-700/40 border-blue-600'
			case 'error':
				return 'bg-stone-950/40 border-stone-900'
			default:
				return 'bg-gray-700 border-gray-600'
		}
	}

	const getIcon = () => {
		switch (type) {
			case 'success':
				return <Icons.Sucess className='size-6 shrink-0 text-green-300' />
			case 'warning':
				return <Icons.Alert className='size-6 shrink-0 text-yellow-300' />
			case 'info':
				return <Icons.Info className='size-6 shrink-0 text-blue-300' />
			case 'error':
				return (
					<Icons.NearbyError className='size-6 shrink-0 text-foreground/50' />
				)
			default:
				return null
		}
	}

	const getButtonStyles = () => {
		switch (type) {
			case 'success':
				return 'inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-400/40 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'
			case 'warning':
				return 'inline-flex rounded-md p-1.5 text-yellow-500 hover:bg-yellow-400/40 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50'
			case 'info':
				return 'inline-flex rounded-md p-1.5 text-blue-500 hover:bg-blue-400/40 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50'
			case 'error':
				return 'inline-flex rounded-md p-1.5 text-red-500 hover:bg-stone-900/40 focus:outline-none focus:ring-2 focus:ring-stone-900 focus:ring-offset-2 focus:ring-offset-red-50'
			default:
				return 'inline-flex rounded-md p-1.5 text-gray-500 hover:bg-gray-400/40 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 focus:ring-offset-gray-50'
		}
	}

	return (
		<RadixToast.Root
			ref={forwardedRef}
			asChild
			forceMount
			onOpenChange={onClose}
			duration={2500}
		>
			<motion.li
				layout
				initial={{ x: width + margin }}
				animate={{ x: 0 }}
				exit={{
					opacity: 0,
					zIndex: -1,
					transition: {
						opacity: {
							duration: 0.2,
						},
					},
				}}
				transition={{
					type: 'spring',
					mass: 1,
					damping: 30,
					stiffness: 200,
				}}
				style={{ width, WebkitTapHighlightColor: 'transparent' }}
			>
				<div
					className={`flex flex-wrap items-center justify-between overflow-hidden rounded-lg border ${getToastStyles()} text-sm text-foreground shadow-sm backdrop-blur`}
				>
					{/*   <RadixToast.Title className="ToastTitle">Scheduled: Catch up</RadixToast.Title> */}
					<RadixToast.Description className='flex items-center truncate p-3.5'>
						<div className='flex-shrink-0'>{getIcon()}</div>
						<span className='ml-3 text-sm'>{text}</span>
					</RadixToast.Description>
					<div className='ml-auto pl-3'>
						<div className='mr-4'>
							<RadixToast.Close className={cn(getButtonStyles(), 'transient')}>
								<Icons.Close className='h-5 w-5' />
							</RadixToast.Close>
						</div>
					</div>
				</div>
			</motion.li>
		</RadixToast.Root>
	)
})
export function ToastProvider({ children }: { children: ReactNode }) {
	const { messages, removeToast } = useToastStore()

	return (
		<RadixToast.Provider>
			{children}

			<AnimatePresence mode='popLayout'>
				{messages.map(toast => (
					<Toast
						key={toast.id}
						text={toast.text}
						type={toast.type}
						onClose={() => removeToast(toast.id)}
					/>
				))}
			</AnimatePresence>

			<RadixToast.Viewport className='fixed right-4 top-4 flex w-80 flex-col-reverse gap-3 max-sm:top-20' />
		</RadixToast.Provider>
	)
}
