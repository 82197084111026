import { type ComponentType, type ReactNode, Suspense } from 'react'

import Loading from '../loaders/Loading'

interface LazyLoadProps {
	component: React.LazyExoticComponent<ComponentType<any>>
	loading?: ReactNode
	error?: ReactNode
}

const LazyLoad: React.FC<LazyLoadProps> = ({
	component: Component,
	loading,
	// error,
}) => {
	return (
		<Suspense fallback={loading || <Loading />}>
			<Component />
		</Suspense>
	)
}

export default LazyLoad
