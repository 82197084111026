import { create } from 'zustand'

export interface ToastStore {
	messages: {
		id: string
		text: string
		type: 'success' | 'warning' | 'info' | 'error'
	}[]
	showToast: (
		text: string,
		type: 'success' | 'warning' | 'info' | 'error',
	) => void
	removeToast: (id: string) => void
}

export const useToastStore = create<ToastStore>(set => ({
	messages: [],
	showToast: (text: string, type: 'success' | 'warning' | 'info' | 'error') => {
		set(state => ({
			messages: [
				...state.messages,
				{ id: window.crypto.randomUUID(), text, type },
			],
		}))
	},
	removeToast: (id: string) =>
		set(state => ({
			messages: state.messages.filter(t => t.id !== id),
		})),
}))
