// import { Notify } from 'notiflix';
import { Suspense, lazy, useEffect, useRef } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuthStatus } from '@/context/authContext'
import { notify } from '@/services/notification'

import Loading from '@/components/loaders/Loading'

// Lazy load the Dashboard component
const DashboardLazy = lazy(
	() => import('@/app/dashboard/dashboard'),
)

export const RequireAuth = () => {
	const isAuthenticated = useAuthStatus(
		state => state.isAuthenticated,
	)
	const isLoading = useAuthStatus(state => state.isLoading) // Add this selector
	const retry = useAuthStatus(state => state.checkAuth)
	// Add request tracking ref
	const hasCheckedRef = useRef(false)
	const retryCountRef = useRef(0)

	useEffect(() => {
		const checkAuthentication = async () => {
			// Only check if not authenticated, not loading, and hasn't checked yet
			if (
				!isAuthenticated &&
				!isLoading &&
				!hasCheckedRef.current
			) {
				try {
					// console.log(
					// 	`[RequireAuth] Making auth check request #${retryCountRef.current + 1}`,
					// )
					const startTime = performance.now()

					hasCheckedRef.current = true // Set flag before request
					retry()

					const endTime = performance.now()
					console.log(
						`[RequireAuth] Request completed in ${endTime - startTime}ms`,
					)
					retryCountRef.current++
				} catch (error) {
					hasCheckedRef.current = false // Reset on error

					notify({
						message:
							'Error checking authentication. Please try again.',
						type: 'error',
					})
				}
			}
		}

		checkAuthentication()
	}, [isAuthenticated, isLoading]) // Remove retry from dependencies

	if (isLoading) {
		return <Loading />
	}

	return (
		<Suspense fallback={<Loading />}>
			{isAuthenticated ? (
				<DashboardLazy />
			) : (
				<Navigate to='/' replace />
			)}
		</Suspense>
	)
}
