// store/authStore.ts
import { getCurrentUser } from '@/core/api/access/fns'
import { createSelectors } from '@/lib/utils/createSelectors'
import { AxiosError } from 'axios'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface AuthState {
	isAuthenticated: boolean | undefined
	user: User | null
	isAdmin: boolean
	isVerified: boolean
	login: (userDetails: {
		user: any
		isAdmin: boolean
		isVerified: boolean
		isApproved: boolean
		settings?: UserSettings
		isAuthenticated?: boolean
	}) => void
	logout: () => void
	checkAuth: () => void
	isApproved: boolean | undefined
	settings: UserSettings | null
	setSettings: (settings: UserSettings) => void
	isLoading: boolean
}

const initialState = {
	isAuthenticated: false,
	user: null,
	isAdmin: false,
	isVerified: false,
	isApproved: false,
	settings: null,
}

export const useAuthStatus = create<AuthState>()(
	devtools(
		(set, get) => ({
			...initialState,
			isLoading: false,
			setSettings: (settings: UserSettings) => {
				set({ settings })
			},
			login: userDetails => {
				localStorage.setItem(
					`${userDetails.user._id}-authToken`,
					userDetails.user.token,
				)
				localStorage.setItem(
					`${userDetails.user._id}-loginTime`,
					new Date().toISOString(),
				)
				// Update the current user globally
				localStorage.setItem(
					'currentUserId',
					userDetails.user._id,
				)

				//Set time in local storage
				localStorage.setItem(
					`${userDetails.user._id}-id`,
					new Date().toISOString(),
				)

				set({
					isAuthenticated: true,
					user: userDetails.user,
					isAdmin: userDetails.isAdmin,
					isVerified: userDetails.isVerified,
					isApproved: userDetails.isApproved,
					settings: userDetails.settings,
				})
			},

			checkAuth: async () => {
				if (get().isLoading) return // Prevent concurrent checks
				set({ isLoading: true })
				// Check if the user is authenticated
				const isAuthenticated = get().isAuthenticated
				if (isAuthenticated) {
					console.log(
						'User is authenticated - skipping checkAuth',
					)
					return
				}
				try {
					const currentUser = await getCurrentUser() // Fetch the current user (API call)
					if (currentUser) {
						set({
							isAuthenticated: true,
							user: currentUser.user,
							isAdmin: currentUser.isAdmin,
							isVerified: currentUser.isVerified,
						})
					}
				} catch (error) {
					console.error(
						'Error checking authentication:',
						error,
					)
					if (
						error instanceof AxiosError &&
						(error.response?.status === 401 ||
							error.response?.status === 403)
					) {
						get().logout()
					}
				} finally {
					set({ isLoading: false })
				}
			},

			logout: () => {
				const userId = localStorage.getItem('currentUserId')
				if (userId) {
					// Clear user-specific keys
					localStorage.removeItem(`${userId}-authToken`)
					localStorage.removeItem(`${userId}-loginTime`)
					localStorage.removeItem('currentUserId')
				}

				set({
					isAuthenticated: false,
					user: null,
					isAdmin: false,
					isVerified: false,
				})

				localStorage.removeItem('authToken')
				localStorage.removeItem(`${get().user?._id}-id`)
			},
		}),
		{
			enabled: true,
			store: 'authStore',
			name: 'authStore',
		},
	),
)

export const authActions = {
	logout: () => useAuthStatus.getState().logout(),
	login: (userDetails: {
		user: any
		isAdmin: boolean
		isVerified: boolean
		isApproved: boolean
	}) => useAuthStatus.getState().login(userDetails),
	checkAuth: () => useAuthStatus.getState().checkAuth(),
}

export const auth = createSelectors(useAuthStatus)

//helper functions to get time of last login
//Auth expires in 120 minutes then auto logout
export const getLastLoginTime = (userId: string) => {
	const lastLoginTime = localStorage.getItem(`${userId}-id`)
	return lastLoginTime ? new Date(lastLoginTime) : null
}

export const isAuthExpired = (userId: string) => {
	const lastLoginTime = getLastLoginTime(userId)
	if (!lastLoginTime) {
		return true
	}

	const now = new Date()
	const diffTime = now.getTime() - lastLoginTime.getTime()
	const diffMinutes = Math.round(diffTime / (1000 * 60))

	// Check if the session has expired
	if (diffMinutes >= 120) {
		console.log('Session expired. Logging out...')
		authActions.logout() // Perform logout if expired
		return true
	}

	return false
}
