import { notificationProps } from '@/constants/notifications'
import { notifications } from '@mantine/notifications'
import {
	type DefaultOptions,
	QueryCache,
	QueryClient,
} from '@tanstack/react-query'

const defaultOptions: DefaultOptions = {
	queries: {
		staleTime: 60 * 1000,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		retry: false,
	},
	mutations: {
		retry: false,
	},
}

const queryCache = new QueryCache({
	onError: (error, query) => {

		if (!query.meta?.skipErrorToast && error instanceof Error) {
			notifications.show(
				notificationProps({
					status: 'error',
					title: 'Error',
					message: error.message,
				}),
			)
		}
	},
})

export const queryClient = new QueryClient({
	defaultOptions,
	queryCache,
})
