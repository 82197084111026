import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { queryClient } from './queryClient'

interface QueryProviderProps {
	children: React.ReactNode
}

export default function QueryProvider({
	children,
}: Readonly<QueryProviderProps>) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	)
}
