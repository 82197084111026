import { routes } from '@/core/api/common'
import axios from '@/core/api/common/axios'
import { isAxiosError } from 'axios'

import type { APICycleResp, PaymentDateRangeResponse, PsData } from './types'

export const registerFee = async (data: { studentId: string }) => {
	try {
		const response = await axios.post(routes.payments.registerFee, data)
		return response.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		}
		throw new Error('Network error')
	}
}

export const getStudentsByClass = async (className: string) => {
	try {
		const response = await axios.get(
			routes.payments.getStudentsByClass(className),
		)
		return response.data.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		}
		throw new Error('Network error')
	}
}

export const deletePayment = async (id: string) => {
	try {
		const response = await axios.delete(routes.payments.deletePayment(id))
		return response.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		}
		throw new Error('Network error')
	}
}

export const bulkRegisterFee = async (data: { studentIds: string[] }) => {
	try {
		const response = await axios.post(routes.payments.bulkRegisterFee, data)
		return response.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		}
		throw new Error('Network error')
	}
}

export const bulkRemoveFee = async (data: { ids: string[] }) => {
	try {
		const response = await axios.delete(routes.payments.bulkRemoveFee, {
			data,
		})
		return response.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		}
		throw new Error('Network error')
	}
}

export const getSchoolStats = async () => {
	try {
		const response = await axios.get(routes.payments.stats)
		return response.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const getFeeDocuments = async () => {
	try {
		const response = await axios.get(routes.payments.getFees)
		return response.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const getStudentHistory = async (id: string) => {
	if (!id) return
	try {
		const response = await axios.get(routes.custom.feeHistory(id))
		return response.data.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const registerCustomFee = async (data: {
	studentId: string
	payId: string
	paymentType: string
}) => {
	try {
		const response = await axios.post(routes.payments.customFee, data)
		return response.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const createPaymentTransaction = async (data: {
	studentIds: string | string[]
	payId?: string
}) => {
	try {
		const response = await axios.post(routes.payments.transaction, data)
		return response.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const deletePaymentTransaction = async (studentIds: string[]) => {
	try {
		const response = await axios.put(routes.payments.transaction, {
			studentIds,
		})
		return response.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const generateInvoice = async (data: {
	studentId: string
	paymentId: string
}) => {
	try {
		const response = await axios.post(routes.payments.generateInvoice, data)

		return response.data.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const getBillCycles = async () => {
	try {
		const response = await axios.get(routes.payments.cycles)

		return response.data.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export interface Datum {
	label: string
	value: string
}
export const getPaymentsByPaymentId = async (
	id: string,
): Promise<APICycleResp> => {
	try {
		const { data } = await axios.get<{
			data: PsData
			message: string
		}>(routes.payments.getByPaymentId(id))
		return data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? { code: 'ECONNABORTED', message: 'Request timeout' }
					: error.response.data
			throw new Error(apiError.message)
		}
		throw new Error('Network error')
	}
}

export const getPaymentsByDateRange = async (
	startDate?: Date,
	endDate?: Date,
): Promise<PaymentDateRangeResponse> => {
	const params = new URLSearchParams()

	if (startDate) {
		params.append('startDate', startDate.toISOString().split('T')[0])
	}

	if (endDate) {
		params.append('endDate', endDate.toISOString().split('T')[0])
	}

	const { data } = await axios.get<{
		success: boolean
		data: PaymentDateRangeResponse
	}>(routes.payments.getByDateRange(params.toString()))

	return data.data
}
