import ReactDOM from 'react-dom/client'
import {
	RouterProvider,
	createBrowserRouter,
} from 'react-router-dom'

import '@mantine/notifications/styles.css'
import '@mantine/spotlight/styles.css'

import './App.css'
import {
	Layout,
	Login,
	NotFound,
	ResetPasswordPage, 
} from './app'
import { RequireAuth } from './app/layouts/RequireAuth.tsx'
import LazyLoad from './components/LazyLoad/index.tsx'
import {
	dashboardRoutes,
	registrationRoutes,
	serviceRoutes,
	userRoutes,
} from './routes'
import Editor from './sections/temp/text-editor/editor.tsx'
import Providers from './shared/providers/index.tsx'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <LazyLoad component={NotFound} />,
		children: [
			{
				path: '/',
				element: <LazyLoad component={Login} />,
			},
			{
				path: '/reset-password/:token',
				element: <LazyLoad component={ResetPasswordPage} />,
			},
			{
				path: '/dashboard',
				// loader: UserLoader,
				element: <RequireAuth />,
				errorElement: <LazyLoad component={NotFound} />,
				children: [
					...dashboardRoutes,
					...userRoutes,
					...serviceRoutes,
					...registrationRoutes,
				],
			},

			{
				path: '/grid',
				element: <Editor />,
			},
			{
				path: '*',
				element: <LazyLoad component={NotFound} />,
			},
		],
	},
])

ReactDOM.createRoot(
	document.getElementById('root')!,
).render(
	<Providers>
		<RouterProvider router={router} />
	</Providers>,
)
