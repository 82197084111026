import { routes } from '@/core/api/common'
import { AxiosError } from 'axios'

import axios, { api } from '../../common/axios'
import type { StudentProfile } from './types'

export const getStudents = async () => {
	const { data } = await api.get<{
		statusCode: string
		message: string
		data: Student[]
	}>(routes.students.list)
	return data.data
}
export const getStudent = async (id: string) => {
	const { data } = await api.get<{
		statusCode: string
		message: string
		data: StudentProfile
	}>(routes.students.getDetail(id))
	return data.data
}

export const createStudent = async (data: Partial<Student>) => {
	const res = await api.post(routes.students.registerStudent, data)

	return res.data
}

// export const createSeed = async () => {
// 	const res = await axios.post(map.students.seed)
// 	return res.data
// }

// export const resetStudents = async () => {
// 	const res = await axios.delete(map.students.reset)
// 	return res.data
// }

export const removeStudent = async (id: string) => {
	const res = await api.delete(routes.students.getDetail(id))
	return res.data
}

export const updateStudent = async (data: {
	id: string
	field: string
	value: string
}) => {
	const res = await api.patch(routes.students.getDetail(data.id), data)
	return res.data
}

export const getStudentsByClass = async (className: string) => {
	try {
		const res = await axios.get(routes.students.getClass(className))
		return res.data.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		} else {
			throw new Error('Network error')
		}
	}
}

export const getStudentWithFee = async (id: string) => {
	try {
		const { data } = await axios.get<{
			message: string
			data: StudentProfile
		}>(routes.students.studentWithFee(id))
		return data.data
	} catch (error) {
		if (error instanceof Error) {
			throw new Error(error.message)
		} else {
			throw new Error('Network error')
		}
	}
}

export const getSortedStudents = async () => {
	try {
		const { data } = await api.get<{
			statusCode: string
			message: string
			data: Student[]
		}>(routes.students.monthlyAggregated)
		return data.data
	} catch (error: any) {
		if (error.response) {
			throw new Error(error.response.data.message || 'An error occurred')
		} else {
			throw new Error('Network error')
		}
	}
}

export const updateFee = async (data: {
	studentId: string
	amount: number
	remarks: string
}) => {
	const res = await api.put(routes.students.changeFee, data)
	return res.data
}

// export const getStudentsWithFeeDocs = async () => {
// 	try {
// 		const res = await axios.get(routes.students.allStudentsWithFee)
// 		return res.data
// 	} catch (error: any) {
// 		if (error.response) {
// 			throw new Error(error.response.data.message || 'An error occurred')
// 		} else {
// 			throw new Error('Network error')
// 		}
// 	}
// }

export const updateSection = async (data: { id: string; section: string }) => {
	try {
		const res = await axios.put(routes.students.changeSection, data)
		return res.data
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.message)
		} else {
			throw new Error('Network error')
		}
	}
}

export const getStudentFeeDocs = async (studentId: string) => {
	try {
		const res = await axios.get(routes.payments.studentFeeDocs(studentId))
		return res.data
	} catch (error) {
		if (error instanceof AxiosError) {
			throw new Error(error.message)
		} else {
			throw new Error('Network error')
		}
	}
}
