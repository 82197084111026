export enum BasePath {
	School = '/school',
	Students = '/school/students',
	Events = '/school/events',
	Admissions = '/school/admissions',
	Teachers = '/school/teachers',
	Files = '/files',
	Notifications = '/notifications',
	Expenses = '/school/expenses',
	Sessions = '/sessions',
	payments = '/school/payments',
	users = '/users',
	custom = '/school/custom',
	Classes = '/school/classes',
	Peer = '/peer',
	Stats = '/school/stats',
	Invoice = '/invoices',
	Issues = '/school/issues',
	Settings = '/settings',
	Subjects = '/school/subjects',
}

/*---------------  (Subjects Block)  ----!>*/
interface ISubjects {
	classSubjects: (classId: string) => string
}
const buildSubjectsPaths = (): ISubjects => {
	const base = BasePath.Subjects
	return {
		classSubjects: (classId: string) => makeRoute(base, `/${classId}`),
	}
}
/*---------------  (Settings Block)  ----!>*/
interface INotificationPaths {
	list: string
	create: string
	getById: (id: string) => string
	delete: string
	delById: (id: string) => string
	markDeleted: (id: string) => string
	markRead: (id: string) => string
}

const notificationPaths = (): INotificationPaths => {
	const base = BasePath.Notifications

	return {
		list: base,
		create: base,
		getById: (id: string) => makeRoute(base, `/${id}`),
		delete: makeRoute(base, '/delete'),
		delById: (id: string) => makeRoute(base, `/${id}`),
		markDeleted: (id: string) => makeRoute(base, `/${id}/delete`),
		markRead: (id: string) => makeRoute(base, `/${id}/read`),
	}
}
/*---------------  (Settings Block)  ----!>*/
interface ISettings {
	getSettings: string
	updateSettings: string
}

const buildSettingsPaths = (): ISettings => {
	const base = BasePath.Settings
	return {
		getSettings: base,
		updateSettings: base,
	}
}

/*---------------  (Issues Block)  ----!>*/
interface IIssuesPath {
	getIssues: string
	postIssue: string
	postReply: (id: string) => string
	deleteIssue: (id: string) => string
	deleteReply: (id: string) => string
	updateIssue: (id: string) => string
}

const buildIssuePaths = (): IIssuesPath => {
	const base = BasePath.Issues
	return {
		getIssues: makeRoute(base),
		postIssue: makeRoute(base),
		postReply: (id: string) => makeRoute(base, `/${id}`),
		deleteIssue: (id: string) => makeRoute(base, `/${id}`),
		deleteReply: (id: string) => makeRoute(base, `/${id}`),
		updateIssue: (id: string) => makeRoute(base, `/${id}`),
	}
}
/*---------------  (Events Block)  ----!>*/
interface IEventPaths {
	list: string
	create: string
	update: (id: string) => string
	delete: (id: string) => string
}
const buildEventPaths = (): IEventPaths => {
	const base = BasePath.Events
	return {
		list: base,
		create: makeRoute(base),
		update: (id: string) => makeRoute(base, `/${id}`),
		delete: (id: string) => makeRoute(base, `/${id}`),
	}
}
/*---------------  (Stats Block)  ----!>*/
interface IStats {
	getStats: (payId: string) => string
	unpaidStudentList: (payId: string) => string
	collection: string
}
const buildStatsPaths = (): IStats => {
	const base = BasePath.Stats
	return {
		getStats: (payId: string) => makeRoute(base, `/global/${payId}`),
		collection: makeRoute(base, '/collection'),
		unpaidStudentList: (payId: string) => makeRoute(base, `/students/${payId}`),
	}
}
/*---------------  (Stats Block)  ----!>*/
/*---------------  (Peer Block)  ----!>*/
interface IPeer {
	getChatToken: string
}
const buildPeerRoutes = (): IPeer => {
	const base = BasePath.Peer
	return {
		getChatToken: makeRoute(base, '/chat-token'),
	}
}
/*---------------  (Peer Block)  ----!>*/

interface IClassRoutes {
	addSubject: (classId: string) => string
	removeSubject: (classId: string) => string
	addClassTeacher: (classId: string) => string
	replaceClassTeacher: (classId: string) => string
}

const buildClassRoutes = (): IClassRoutes => {
	const base = BasePath.Classes
	return {
		addSubject: (classId: string) => makeRoute(base, `/subject/${classId}`),
		removeSubject: (classId: string) => makeRoute(base, `/subject/${classId}`),
		addClassTeacher: (classId: string) =>
			makeRoute(base, `/teacher/${classId}`),
		replaceClassTeacher: (classId: string) =>
			makeRoute(base, `/replace-teacher/${classId}`),
	}
}

/*---------------  (Invoice Block)  ----!>*/
interface IInvoicePaths {
	generateInvoice: string
}
const buildInvoicePaths = (): IInvoicePaths => {
	const base = BasePath.Invoice
	return {
		generateInvoice: makeRoute(base + '/generate'),
	}
}
/*---------------  (Invoice Block)  ----!>*/
interface VerificationPaths {
	resetPass: (token: string) => string
	verifyUser: string
	forgotrPass: string
	verifyEmails: string
	reissueToken: string
	signup: string
	deleteUser: (id: string) => string
	requestNewToken: string
	changePassword: string
}

const buildVerificationPaths = (): VerificationPaths => {
	const base = BasePath.users
	return {
		resetPass: (token: string) => makeRoute(base, `/reset-password/${token}`),
		forgotrPass: base + '/forgot-password',
		verifyUser: base + '/verify-user',
		verifyEmails: base + '/verify-email',
		reissueToken: base + '/reissue-token',
		requestNewToken: base + '/reissue-token',
		signup: base + '/register',
		deleteUser: (id: string) => makeRoute(base, `/${id}`),
		changePassword: base + '/change-password',
	}
}

/*<---------------  (User Block)  ----!>*/
interface UserPaths {
	login: string
	register: string
	forgotPassword: string
	resetPassword: string
	changePassword: string
	updateProfile: (id: string) => string
	getProfile: string
	logout: string
	temporary: string
	session: string
	getAllUsers: string
	updateApproval: (id: string) => string
	checkAvailability: (value: string, type: 'email' | 'username') => string
}

export const buildUserPaths = (): UserPaths => {
	const base = BasePath.users
	return {
		login: base + '/login',
		register: base + '/register',
		forgotPassword: base + '/forgot-password',
		resetPassword: base + '/reset-password',
		changePassword: base + '/change-password',
		updateProfile: (id: string) => makeRoute(base, `/${id}`),
		getProfile: base + '/profile',
		logout: base + '/logout',
		temporary: base + '/aux',
		session: base + '/session',
		getAllUsers: base,
		updateApproval: (id: string) => makeRoute(base, `/approve/${id}`),
		checkAvailability: (value: string, type: 'email' | 'username') => {
			return makeRoute(base, `/check-availability?${type}=${value}`)
		}
	}
}
/*<---------------  (User Block)  ----!>*/
interface IExpensePaths {
	list: string
	create: string
	reset: string
	createMany: string
	getExpense: (id: string) => string
	update: (id: string) => string
	delete: (id: string) => string
	uploadReceipt: (id: string) => string
}

const makeRoute = (base: string, path = ''): string => `${base}${path}`

const buildExpensePaths = (): IExpensePaths => {
	const base = BasePath.Expenses
	return {
		list: makeRoute(base),
		create: base,
		reset: makeRoute(base, '/reset'),
		createMany: makeRoute(base, '/insertMany'),
		getExpense: (id: string) => makeRoute(base, `/${id}`),
		update: (id: string) => makeRoute(base, `/${id}`),
		delete: (id: string) => makeRoute(base, `/${id}`),
		uploadReceipt: (id: string) => makeRoute(base, `/upload/${id}`),
	}
}
/*---------------  (Sessions Block)  ----!>*/
interface ISessions {
	list: string
	deleteSession: (id: string) => string
	deleteSessions: string
}

const buildSessionPaths = (): ISessions => {
	const base = BasePath.Sessions
	return {
		list: base,
		deleteSession: (id: string) => makeRoute(base, `/${id}`),
		deleteSessions: makeRoute(base, '/ids'),
	}
}

/*---------------  (Sessions Block)  ----!>*/

interface IStudentPaths {
	list: string
	getClass: (className: string) => string
	studentWithFee: (id: string) => string
	getDetail: (id: string) => string
	registerStudent: string
	allStudentsWithFee: string
	sorted: string
	monthlyAggregated: string
	changeFee: string
	changeSection: string
}

const buildStudentPaths = (): IStudentPaths => {
	const base = BasePath.Students
	return {
		list: base,
		getClass: (className: string) => makeRoute(base, `/class/${className}`),
		studentWithFee: (id: string) => makeRoute(base, `/${id}`),
		getDetail: (id: string) => makeRoute(base, `/${id}`),
		sorted: makeRoute(base, '/sorted'),
		changeFee: makeRoute(base, '/update-fee'),
		registerStudent: base,
		changeSection: makeRoute(base, '/update-section'),
		allStudentsWithFee: makeRoute(base, '/with-payments'),
		monthlyAggregated: makeRoute(base, '/monthly-aggregated'),
	}
}

interface IPaymentPaths {
	registerFee: string
	bulkRegisterFee: string
	bulkRemoveFee: string
	getStudentsByClass: (className: string) => string
	deletePayment: (id: string) => string
	stats: string
	getFees: string
	studentHistory: (id: string) => string
	customFee: string
	changeFee: string
	studentFeeDocs: (id: string) => string
	transaction: string
	generateInvoice: string
	cycles: string
	getByPaymentId: (id: string) => string
	getByDateRange: (range: string) => string
}

const buildPaymentPaths = (): IPaymentPaths => {
	const base = BasePath.payments
	return {
		registerFee: base,
		bulkRemoveFee: makeRoute(base, '/delete'),
		getStudentsByClass: (className: string) =>
			makeRoute(base, `/class/${className}`),
		deletePayment: (id: string) => makeRoute(base, `/id/${id}`),
		bulkRegisterFee: makeRoute(base, '/multi-insert'),
		stats: makeRoute(base, '/stats'),
		getFees: makeRoute(base),
		studentHistory: (id: string) => makeRoute(base, `/history/${id}`),
		customFee: makeRoute(base, '/custom'),
		changeFee: makeRoute(base, '/update-fee'),
		studentFeeDocs: (studentsId: string) =>
			makeRoute(base, `/student/${studentsId}`),
		transaction: makeRoute(base, '/transaction'),
		generateInvoice: makeRoute(base, '/invoice'),
		cycles: makeRoute(base, '/cycles'),
		getByPaymentId: (id: string) => makeRoute(base, `/bill-cycle/${id}`),
		getByDateRange: (range: string) => makeRoute(base, `/date-range?${range}`),
	}
}

interface ICustomRouter {
	sorted: string
	feeHistory: (studentId: string) => string
}

const buildCustomRouter = (): ICustomRouter => {
	const base = BasePath.custom
	return {
		sorted: makeRoute(base),
		feeHistory: (studentId: string) => makeRoute(base, `/id/${studentId}`),
	}
}
export const routes = {
	issues: buildIssuePaths(),
	expenses: buildExpensePaths(),
	users: buildUserPaths(),
	sessions: buildSessionPaths(),
	students: buildStudentPaths(),
	payments: buildPaymentPaths(),
	custom: buildCustomRouter(),
	classes: buildClassRoutes(),
	peer: buildPeerRoutes(),
	verification: buildVerificationPaths(),
	stats: buildStatsPaths(),
	events: buildEventPaths(),
	invoice: buildInvoicePaths(),
	settings: buildSettingsPaths(),
	notifications: notificationPaths(),
	subjects: buildSubjectsPaths(),
}
