import './loader.css'

export default function Loading() {
	return (
		<div className='h-screen w-full'>
			<div className='loader'>
				<div className='circle'>
					<div className='dot'></div>
					<div className='outline'></div>
				</div>
				<div className='circle'>
					<div className='dot'></div>
					<div className='outline'></div>
				</div>
				<div className='circle'>
					<div className='dot'></div>
					<div className='outline'></div>
				</div>
				<div className='circle'>
					<div className='dot'></div>
					<div className='outline'></div>
				</div>
			</div>
		</div>
	)
}
