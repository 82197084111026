export const queryKeys = {
	students: 'students',
	student: (id: string) => ['students', id],
	classes: 'classes',
	feeDocs: (id: string) => ['feeDocs', id],
	invoice: (id: string) => ['invoice', `${id}`],
	currentUser: 'user',
	users: 'users',
	fees: 'feeDocs',
	sessions: 'sessions',
	billCycle: 'billCycle',
	stats: 'stats',
	events: 'events',
	expenses: 'expenses',
	issues: 'issues',
	notifications: 'notifications',
	subjects: 'subjects',
} as const
