import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

// utils/pathUtils.js
export const shouldSavePath = (path: string) => {
	// Only save paths that start with '/dashboard' or other allowed paths
	return path.startsWith('/dashboard')
}
