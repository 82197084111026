import { AccountManager, Mail } from '@/app'

import LazyLoad from '@/components/LazyLoad'

export const userRoutes = [
	{
		path: 'inbox',
		element: <LazyLoad component={Mail} />,
	},
	{
		path: 'settings',
		element: <LazyLoad component={AccountManager} />,
	},
]
