import { isAxiosError } from 'axios'

import { routes } from '../common'
import axios, { api } from '../common/axios'
import { map } from '../common/urlMapper'
import type { CurrentUserData } from './types'

interface LoginData {
	user: User
	isAdmin: boolean
	isVerified: boolean
	permissions: string[]
	settings: UserSettings
}

export const signIn = async (credentials: {
	email: string
	password: string
}) => {
	try {
		const { data } = await api.post<{
			statusCode: string
			message: string
			data: LoginData
		}>(routes.users.login, credentials)
		return data.data
	} catch (error) {
		throw new Error(`${error}`)
	}
}

export const getCurrentUser = async () => {
	try {
		const { data } = await api.get<{
			statusCode: string
			message: string
			data: CurrentUserData
		}>(map.userPaths.currentUser)

		return data.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const logout = async () => {
	await axios.post(`${map.userPaths.logout}`).catch(e => {
		throw new Error(`Error: ${e}`)
	})
}

export const getStatus = async () => {
	try {
		const res = await axios.get(map.userPaths.staus)
		return res.data.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const getSession = async () => {
	try {
		const res = await axios.get(routes.sessions.list)

		return res.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const updateProfile = async (
	id: string,
	{ data }: { data: Partial<User> },
) => {
	try {
		const res = await axios.patch(
			routes.users.updateProfile(id),
			data,
		)

		return res.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const getStudentHistory = async (id: string) => {
	if (!id) return
	try {
		const response = await axios.get(
			routes.payments.studentHistory(id),
		)
		return response.data.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? {
							code: 'ECONNABORTED',
							message: 'Request timeout',
						}
					: error.response.data
			throw new Error(apiError.message)
		} else {
			throw new Error('Network error')
		}
	}
}

export const registerCustomFee = async (data: {
	studentId: string
	payId: string
	paymentType: string
}) => {
	try {
		const response = await axios.post(
			routes.payments.customFee,
			data,
		)
		return response.data
	} catch (error) {
		if (isAxiosError(error) && error.response) {
			const apiError: ApiError =
				error.code === 'ECONNABORTED'
					? {
							code: 'ECONNABORTED',
							message: 'Request timeout',
						}
					: error.response.data
			throw new Error(apiError.message)
		} else {
			throw new Error('Network error')
		}
	}
}

export const deleteSession = async (id: string) => {
	try {
		const res = await axios.delete(
			routes.sessions.deleteSession(id),
		)
		return res.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const deleteSessions = async (ids: string[]) => {
	try {
		if (!ids.length) return
		const data = { ids }

		const res = await axios.delete(
			routes.sessions.deleteSessions,
			{
				data,  
			},
		)

		return res.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}
export const registerUser = async (data: Partial<User>) => {
	try {
		const res = await axios.post(
			routes.users.register,
			data,
		)
		return res.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const getAllUsers = async () => {
	try {
		const res = await axios.get(routes.users.getAllUsers)
		return res.data.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const updateApproval = async (id: string) => {
	try {
		const res = await axios.patch(
			routes.users.updateApproval(id),
		)
		return res.data
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}

export const checkAvailability = async (
	type: 'email' | 'username',
	value: string,
) => {
	try {
		const { data } = await api.get<{
			data: {
				email: boolean
				username: boolean
			}
		}>(routes.users.checkAvailability(value, type))
		return data.data[type]
	} catch (error) {
		throw new Error(`Error: ${error}`)
	}
}
