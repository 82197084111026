import { cn } from '@/lib/utils'
import {
	Button,
	Input,
	Notification,
	TextInput,
	Textarea,
	rem,
} from '@mantine/core'
import { createTheme } from '@mantine/core'
import {
	Spotlight,
	type SpotlightActionData,
} from '@mantine/spotlight'

import Icons from '@/components/icons/collection/basic'

import classes from './styles.module.css'

const Spotlightactions: SpotlightActionData[] = [
	{
		id: 'home',
		label: 'Home',
		description: 'Get to home page',
		onClick: () => 'Home',
		leftSection: (
			<Icons.Mail
				style={{ width: rem(24), height: rem(24) }}
				stroke={'1.5'}
				className='mx-1'
			/>
		),
	},
	{
		id: 'dashboard',
		label: 'Dashboard',
		description:
			'Get full information about current system status',
		onClick: () => console.log('Dashboard'),
		leftSection: (
			<Icons.User
				style={{ width: rem(24), height: rem(24) }}
				stroke={'1.5'}
				className='mx-1'
			/>
		),
	},
	{
		id: 'documentation',
		label: 'Documentation',
		description:
			'Visit documentation to lean more about all features',
		onClick: () => console.log('Documentation'),
		leftSection: (
			<Icons.Check
				style={{ width: rem(24), height: rem(24) }}
				stroke={'1.5'}
				className='mx-1'
			/>
		),
	},
]
export const HPSpotlight = () => (
	<Spotlight
		classNames={{
			search: classes.input,
			action: 'mb-2',
		}}
		actions={Spotlightactions}
		nothingFound='Nothing found...'
		highlightQuery
		searchProps={{
			leftSection: (
				<Icons.Search
					style={{ width: rem(20), height: rem(20) }}
					stroke={'1.5'}
				/>
			),
			placeholder: 'Search...',
		}}
	/>
)

export const theme = createTheme({
	colors: {
		brand: [
			'#ecfaf4',
			'#c7f1de',
			'#a2e8c8',
			'#7ddfb3',
			'#58d59d',
			'#33cc87',
			'#2aa76e',
			'#208256',
			'#175d3d',
			'#0e3825',
		],
	},
	components: {
		Button: Button.extend({
			defaultProps: {
				color: '#85E0B7',
				variant: 'outline',
			},
		}),

		TextInput: TextInput.extend({
			classNames: (_theme, props) => ({
				input: cn(
					{
						['focus:ring-0 focus:border-neutral-300/50 transient text-pos-brand placeholder:text-pos-brand text-xs bg-[#ededed07]']:
							props.error,
					},
					'focus:ring-0 focus:border-neutral-300/50 transient text-xs bg-[#ededed07] transient',
				),
				section: cn(
					{
						['text-pos-brand']: props.error,
					},
					'transient',
				),
				label: 'text-[14px]',
				wrapper: 'transient',
			}),
		}),
		Textarea: Textarea.extend({
			classNames: {
				input:
					'focus:ring-0 focus:border-neutral-300/50 transient text-xs bg-[#ededed07]',
			},
		}),
		Input: Input.extend({
			classNames: {
				input:
					'focus:ring-0 focus:border-neutral-300/50 transient text-xs bg-[#ededed07]',
				wrapper: 'bg-[#ededed07]',
			},
		}),
		Notification: Notification.extend({
			classNames: {
				root: 'bg-[rgb(30,30,30)] shadow-lg shadow-black/50',
				title: 'text-foreground-light',
				description: 'text-zinc-400',
			},
		}),
	},
	focusRing: 'never',
	activeClassName: classes.focus,
})
