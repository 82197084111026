// import { NotificationQueue } from '@/services/notification'
import type { NotificationData } from '@mantine/notifications'

export const verificationProps = (user: User): NotificationData =>
	({
		color: 'pink',
		title: 'Important Notice',
		message: 'Please verify your profile for full access.',
		autoClose: 5000,
		style: { backgroundColor: '#232323' },

		id: user?.email,
		radius: 'md',
	}) as NotificationData

export const loginFailedProps = (message: string): NotificationData =>
	({
		color: '#3FCF8E',
		title: 'New Message',
		message,
		autoClose: 15000,
		style: { backgroundColor: '#232323' },

		id: 'new-message',
		radius: 'md',
	}) as NotificationData

export const incomingMessageProps = (message: string): NotificationData =>
	({
		color: '#3FCF8E',
		title: 'New Message',
		message,
		autoClose: 15000,
		style: { backgroundColor: '#232323' },

		id: 'new-message',
		radius: 'md',
	}) as NotificationData

export const emailVerificationProps = (email: string): NotificationData =>
	({
		color: 'pink',
		title: 'Important Notice',
		message: `Please verify your email address: ${email}`,
		autoClose: 5000,
		style: { backgroundColor: '#232323' },

		id: email,
		radius: 'md',
	}) as NotificationData

export const accountSuccessProps = (message: string): NotificationData =>
	({
		color: 'green',
		title: 'Account Created',
		message,
		autoClose: 5000,
		style: { backgroundColor: '#232323' },

		id: 'account-created',
		radius: 'md',
	}) as NotificationData

export const accountVerificationProps = (
	message: string,
	status: 'success' | 'error',
): NotificationData =>
	({
		color: status === 'success' ? 'green' : 'red',
		title: status === 'success' ? 'Success' : 'Error',
		message,
		autoClose: 5000,
		style: { backgroundColor: '#232323' },

		id: 'account-verification',
		radius: 'md',
	}) as NotificationData

export const notificationProps = ({
	status,
	message,
	title,
}: {
	status: 'success' | 'error'
	message: string
	title?: string
}): NotificationData =>
	({
		color: status === 'success' ? '#3FCF8E' : '#FF6C37',
		title: title || 'Success',
		message,
		autoClose: 5000,
		styles: {
			// backgroundColor: '#232323',
			body: { backgroundColor: '#232323' },
			root: { backgroundColor: '#232323' },
		},
		id: 'success',
		radius: 'md',
	}) as NotificationData
