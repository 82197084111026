import { Calendar, Faqs, FeeCycleLoader, StatManager, Tree } from '@/app'
import CustomError from '@/app/error/custom-error'

import LazyLoad from '@/components/LazyLoad'

export const serviceRoutes = [
	{
		path: 'faqs',
		element: <LazyLoad component={Faqs} />,
	},
	{
		path: 'stats',
		element: <LazyLoad component={Faqs} />,
	},
	{
		path: 'tree',
		element: <LazyLoad component={Tree} />,
	},
	{
		path: 'calender',
		element: <LazyLoad component={Calendar} />,
	},
	{
		path: 'stat-manager',
		loader: FeeCycleLoader,
		errorElement: <CustomError />,
		element: <LazyLoad component={StatManager} />,
	},
]
