import type { ComponentPropsWithoutRef, SVGProps } from 'react'

function XMark(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
				d='M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243'
			></path>
		</svg>
	)
}

export function MaterialSymbolsPersonRounded(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M12 12q-1.65 0-2.825-1.175T8 8t1.175-2.825T12 4t2.825 1.175T16 8t-1.175 2.825T12 12m-8 6v-.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13t3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2v.8q0 .825-.587 1.413T18 20H6q-.825 0-1.412-.587T4 18'
			></path>
		</svg>
	)
}

function SolarCheckCircleLinear(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<g fill='none' stroke='currentColor' strokeWidth='1.5'>
				<circle cx='12' cy='12' r='10'></circle>
				<path
					strokeLinecap='round'
					strokeLinejoin='round'
					d='m8.5 12.5l2 2l5-5'
				></path>
			</g>
		</svg>
	)
}

export function PhEnvelopeSimpleLight(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 256 256'
			{...props}
		>
			<path
				fill='currentColor'
				d='M224 50H32a6 6 0 0 0-6 6v136a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V56a6 6 0 0 0-6-6m-15.42 12L128 135.86L47.42 62ZM216 194H40a2 2 0 0 1-2-2V69.64l86 78.78a6 6 0 0 0 8.1 0L218 69.64V192a2 2 0 0 1-2 2'
			></path>
		</svg>
	)
}

export function LucideLoader(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
				d='M12 2v4m4.2 1.8l2.9-2.9M18 12h4m-5.8 4.2l2.9 2.9M12 18v4m-7.1-2.9l2.9-2.9M2 12h4M4.9 4.9l2.9 2.9'
			></path>
		</svg>
	)
}

export function MaterialSymbolsLightLockOutline(
	props: SVGProps<SVGSVGElement>,
) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M6.616 21q-.672 0-1.144-.472T5 19.385v-8.77q0-.67.472-1.143Q5.944 9 6.616 9H8V7q0-1.671 1.165-2.835Q10.329 3 12 3t2.836 1.165T16 7v2h1.385q.67 0 1.143.472q.472.472.472 1.144v8.769q0 .67-.472 1.143q-.472.472-1.143.472zm0-1h10.769q.269 0 .442-.173t.173-.442v-8.77q0-.269-.173-.442T17.385 10H6.615q-.269 0-.442.173T6 10.616v8.769q0 .269.173.442t.443.173M12 16.5q.633 0 1.066-.434q.434-.433.434-1.066t-.434-1.066T12 13.5t-1.066.434Q10.5 14.367 10.5 15t.434 1.066q.433.434 1.066.434M9 9h6V7q0-1.25-.875-2.125T12 4t-2.125.875T9 7zM6 20V10z'
			></path>
		</svg>
	)
}

export function ArrowLeft(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='m10.571 16.044l-5.284-3.367q-.38-.238-.38-.675t.38-.679l5.284-3.367q.404-.268.82-.032q.417.236.417.728V11.5h8.615q.213 0 .356.144t.144.357t-.144.356t-.356.143h-8.615v2.848q0 .492-.417.728q-.416.235-.82-.032'
			></path>
		</svg>
	)
}

export function IdCard(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M14.154 12.462h4.077v-1h-4.077zm0-2.77h4.077v-1h-4.077zm-8.385 5.616h6.616v-.166q0-.875-.88-1.355t-2.428-.48t-2.429.48t-.879 1.355zm3.308-3.616q.633 0 1.066-.433q.434-.434.434-1.067t-.434-1.066t-1.066-.434t-1.066.434t-.434 1.066t.434 1.067t1.066.433M3 19V5h18v14zm1-1h16V6H4zm0 0V6z'
			></path>
		</svg>
	)
}

export function ShowIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 16 16'
			{...props}
		>
			<g fill='none'>
				<path
					d='M2.984 8.625v.003a.5.5 0 0 1-.612.355c-.431-.114-.355-.611-.355-.611l.017-.062s.026-.084.047-.145a6.7 6.7 0 0 1 1.117-1.982C4.097 5.089 5.606 4 8 4c2.395 0 3.904 1.089 4.801 2.183a6.7 6.7 0 0 1 1.117 1.982a3.916 3.916 0 0 1 .06.187l.003.013l.002.004v.002a.5.5 0 0 1-.966.258l-.001-.004l-.008-.025a4.9 4.9 0 0 0-.2-.52a5.703 5.703 0 0 0-.78-1.263C11.285 5.912 10.044 5 8 5c-2.044 0-3.286.912-4.028 1.817a5.701 5.701 0 0 0-.945 1.674a3.018 3.018 0 0 0-.035.109l-.008.025z'
					fill='currentColor'
				></path>
				<path
					d='M5.5 9.5a2.5 2.5 0 1 1 5 0a2.5 2.5 0 0 1-5 0z'
					fill='currentColor'
				></path>
			</g>
		</svg>
	)
}

export function HideIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 20 20'
			{...props}
		>
			<g fill='none'>
				<path
					d='M2.854 2.146a.5.5 0 1 0-.708.708l3.5 3.498a8.097 8.097 0 0 0-3.366 5.046a.5.5 0 1 0 .98.204a7.09 7.09 0 0 1 3.107-4.528L7.953 8.66a3.5 3.5 0 1 0 4.886 4.886l4.307 4.308a.5.5 0 0 0 .708-.708l-15-15zm9.265 10.68A2.5 2.5 0 1 1 8.673 9.38l3.446 3.447z'
					fill='currentColor'
				></path>
				<path
					d='M10.123 8.002l3.375 3.374a3.5 3.5 0 0 0-3.374-3.374z'
					fill='currentColor'
				></path>
				<path
					d='M10 6c-.57 0-1.129.074-1.666.213l-.803-.803A7.648 7.648 0 0 1 10 5c3.693 0 6.942 2.673 7.72 6.398a.5.5 0 0 1-.98.204C16.058 8.327 13.207 6 10 6z'
					fill='currentColor'
				></path>
			</g>
		</svg>
	)
}

export function PhHouseLineLight(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 256 256'
			{...props}
		>
			<path
				fill='currentColor'
				d='M240 210h-18v-78.83l5.76 5.76a6 6 0 0 0 8.48-8.49L137.9 30.09a14 14 0 0 0-19.8 0l-98.34 98.35a6 6 0 0 0 8.48 8.49l5.76-5.76V210H16a6 6 0 0 0 0 12h224a6 6 0 0 0 0-12M46 119.17l80.58-80.59a2 2 0 0 1 2.84 0L210 119.17V210h-52v-58a6 6 0 0 0-6-6h-48a6 6 0 0 0-6 6v58H46ZM146 210h-36v-52h36Z'
			></path>
		</svg>
	)
}

export function PhoneIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02z'
			></path>
		</svg>
	)
}

export function SucessIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<g
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
				color='currentColor'
			>
				<path d='M17 3.338A9.95 9.95 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10q-.002-1.03-.2-2'></path>
				<path d='M8 12.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11'></path>
			</g>
		</svg>
	)
}

export function AlertIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M11 15h2v2h-2zm0-8h2v6h-2zm1-5C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 18a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8'
			></path>
		</svg>
	)
}

export function CircumWarning(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M12.5 8.752a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0Z'
			></path>
			<circle cx='11.999' cy='16.736' r='.5' fill='currentColor'></circle>
			<path
				fill='currentColor'
				d='M18.642 20.934H5.385a2.5 2.5 0 0 1-2.222-3.644L9.792 4.421a2.5 2.5 0 0 1 4.444 0l6.629 12.869a2.5 2.5 0 0 1-2.223 3.644M12.014 4.065a1.48 1.48 0 0 0-1.334.814L4.052 17.748a1.5 1.5 0 0 0 1.333 2.186h13.257a1.5 1.5 0 0 0 1.334-2.186L13.348 4.879a1.48 1.48 0 0 0-1.334-.814'
			></path>
		</svg>
	)
}

export function BiInfoSquare(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 16 16'
			{...props}
		>
			<g fill='currentColor'>
				<path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z'></path>
				<path d='m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0'></path>
			</g>
		</svg>
	)
}
const SearchIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
	return (
		<svg
			{...props}
			fill='none'
			height='24'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<circle cx='11' cy='11' fill='none' r='8' stroke='#a3a3a3' />

			<path d='m21 21-4.3-4.3' fill='none' stroke='#a3a3a3' />
		</svg>
	)
}
export const BinIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
	return (
		<svg
			{...props}
			xmlns='http://www.w3.org/2000/svg'
			width='32'
			height='32'
			viewBox='0 0 512 512'
		>
			<path
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='m432 144l-28.67 275.74A32 32 0 0 1 371.55 448H140.46a32 32 0 0 1-31.78-28.26L80 144'
			/>
			<rect
				width='448'
				height='80'
				x='32'
				y='64'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				rx='16'
				ry='16'
			/>
			<path
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='32'
				d='M312 240L200 352m112 0L200 240'
			/>
		</svg>
	)
}

function LetsIconsSortUpLight(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<g fill='none' stroke='currentColor'>
				<path strokeLinecap='round' d='M5 16h8m-8-4h6M5 8h4'></path>
				<path d='m19 6l3 3m-3-3l-3 3m3-3v12'></path>
			</g>
		</svg>
	)
}

export const TickIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			strokeWidth='4'
			strokeLinecap='round'
			strokeLinejoin='round'
			{...props}
		>
			<path d='M20 6 9 17l-5-5'></path>
		</svg>
	)
}

export const ForgotPasswordIcon = (props: ComponentPropsWithoutRef<'svg'>) => {
	return (
		<svg
			{...props}
			width='50'
			height='50'
			viewBox='0 0 50 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M25 15C19.486 15 15 19.486 15 25V29.143C15 30.167 15.897 31 17 31H18C18.2652 31 18.5196 30.8946 18.7071 30.7071C18.8946 30.5196 19 30.2652 19 30V24.857C19 24.5918 18.8946 24.3374 18.7071 24.1499C18.5196 23.9624 18.2652 23.857 18 23.857H17.092C17.648 19.987 20.978 17 25 17C29.022 17 32.352 19.987 32.908 23.857H32C31.7348 23.857 31.4804 23.9624 31.2929 24.1499C31.1054 24.3374 31 24.5918 31 24.857V31C31 32.103 30.103 33 29 33H27V32H23V35H29C31.206 35 33 33.206 33 31C34.103 31 35 30.167 35 29.143V25C35 19.486 30.514 15 25 15Z'
				fill='currentColor'
			/>
		</svg>
	)
}

export function IcSharpNearbyError(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill='currentColor'
				d='M12 7.58L16.42 12L12 16.42L7.58 12zm0 11.62L4.8 12L12 4.8l6 6V7.17l-5.99-5.99L1.18 12.01l10.83 10.83L18 16.83V13.2zm8 .8h2v2h-2zm2-10h-2v8h2z'
			></path>
		</svg>
	)
}
const Icons = Object.assign(
	{},
	{
		Close: XMark,
		Check: SolarCheckCircleLinear,
		Lock: MaterialSymbolsLightLockOutline,
		Mail: PhEnvelopeSimpleLight,
		Loader: LucideLoader,
		User: MaterialSymbolsPersonRounded,
		Left: ArrowLeft,
		ID: IdCard,
		Show: ShowIcon,
		Hide: HideIcon,
		Home: PhHouseLineLight,
		Phone: PhoneIcon,
		Sucess: SucessIcon,
		Alert: AlertIcon,
		Warning: CircumWarning,
		Info: BiInfoSquare,
		Search: SearchIcon,
		Bin: BinIcon,
		Tick: TickIcon,
		SortUp: LetsIconsSortUpLight,
		ForgotPassword: ForgotPasswordIcon,
		NearbyError: IcSharpNearbyError,
	},
)

export default Icons
