import type React from 'react'
import { useRouteError } from 'react-router-dom'

const CustomError: React.FC = () => {
	const error = useRouteError()

	return (
		<div>
			<h1>Oops! Something went wrong.</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p>
				<i>{(error as any)?.statusText || (error as any)?.message}</i>
			</p>
		</div>
	)
}

export default CustomError
