// dynamicImport.ts
import { lazy } from 'react'

const dynamicImport = (
	importFunc: () => Promise<{ default: React.ComponentType<any> }>,
) => {
	return lazy(importFunc)
}

export default dynamicImport
