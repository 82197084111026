import { StrictMode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { NotFound } from '@/app'
import QueryProvider from '@/core/api/common/react-query.tsx'
import { MantineProvider } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'

import { ToastProvider } from '@/components/toast'

import { theme } from './mantine'

export default function Providers({ children }: { children: React.ReactNode }) {
	return (
		<StrictMode>
			<QueryProvider>
				<ErrorBoundary FallbackComponent={NotFound}>
					<MantineProvider
						// withCssVariables={false}
						forceColorScheme='dark'
						theme={theme}
						withCssVariables
					>
						<div>
							<DatesProvider settings={{ locale: 'en', firstDayOfWeek: 0 }}>
								<ToastProvider>{children}</ToastProvider>
							</DatesProvider>
						</div>
					</MantineProvider>
				</ErrorBoundary>
			</QueryProvider>
		</StrictMode>
	)
}
