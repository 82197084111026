// src/utils/notifications.ts
import { NotificationProps } from '@mantine/core'
import { notifications } from '@mantine/notifications'

type NotificationType =
	| 'success'
	| 'error'
	| 'warning'
	| 'info'

interface NotifyProps {
	message: string
	title?: string
	type?: NotificationType
	duration?: number
	opts?: NotificationProps
}

const defaultStyles = {
	body: { backgroundColor: '#232323' },
	root: { backgroundColor: '#232323' },
} as const

const typeColors: Record<NotificationType, string> = {
	success: '#3FCF8E',
	error: '#FF6C37',
	warning: 'orange',
	info: '#097bed',
}

export const notify = ({
	message,
	title,
	type = 'info',
	duration = 5000,
	opts = {},
}: NotifyProps): void => {
	notifications.show({
		title:
			title || type.charAt(0).toUpperCase() + type.slice(1),
		message,
		color: typeColors[type],
		autoClose: duration,
		styles: defaultStyles,
		radius: 'md',
		...opts,
	})
}

export const notifyMissingFields = (
	fields: string[],
): void => {
	notify({
		type: 'warning',
		message: `Please fill in the following fields: ${fields.join(', ')}`,
	})
}

export const notifySuccess = (message: string): void => {
	notify({ type: 'success', message })
}

export const notifyError = (message: string): void => {
	notify({ type: 'error', message })
}
