// Layout Components
import { getCurrentUser, getSession } from '@/core/api/access/fns'
import { queryClient } from '@/core/api/common/queryClient'
import { queryKeys } from '@/core/api/common/queryKeys'
import { getBillCycles } from '@/core/api/school/payments/payments.api'
import { getSortedStudents } from '@/core/api/school/students/fns'
import dynamicImport from '@/lib/utils/dynamicImport'

// Layout Components
const Layout = dynamicImport(() => import('@/app/layouts/rootLayout'))
const ReqAdmin = dynamicImport(() => import('@/app/layouts/reqAdmin'))
const Panel = dynamicImport(() => import('@/app/dashboard/panel'))
const NotFound = dynamicImport(() => import('@/app/error/not-found'))

// Auth Components
const Login = dynamicImport(() => import('@/core/auth/auth-manager'))
const ResetPasswordPage = dynamicImport(
	() => import('@/core/auth/ResetPassword'),
)
const AccountManager = dynamicImport(() => import('@/features/account-manager'))

// Management Components
const ClassManager = dynamicImport(() => import('@/features/class-manager'))
const UserManager = dynamicImport(() => import('@/features/user-manager'))
const FacultyManager = dynamicImport(() => import('@/features/faculty-manager'))
const ExpenseManager = dynamicImport(() => import('@/features/expense-manager'))
const StatManager = dynamicImport(() => import('@/features/stat-manager'))
const PromotionManager = dynamicImport(
	() => import('@/features/promotion-manager'),
)

// Student Related Components
const Admissions = dynamicImport(() => import('@/features/student-manager'))
const StudentProfile = dynamicImport(
	() => import('@/features/profile-manager/students/StudentProfile'),
)
const StudentRegistration = dynamicImport(
	() => import('@/app/forms/steps/student-registration/StudentRegistration'),
)

// Staff Related Components
const Teachers = dynamicImport(
	() => import('@/sections/tables/teachers/TeacherList'),
)
const TeacherRegistration = dynamicImport(
	() => import('@/app/forms/steps/teacher-registration'),
)
const EmployeeRegistration = dynamicImport(
	() => import('@/app/forms/steps/employee-registration'),
)

// File & Data Components
const Files = dynamicImport(
	() => import('@/features/file-manager/file-manager'),
)

const ChangeLog = dynamicImport(() => import('@/features/change-log'))
const Gallery = dynamicImport(() => import('@/features/fee-manager'))

// Communication Tools
const Mail = dynamicImport(() => import('@/app/mail'))
const Chat = dynamicImport(() => import('@/features/chat-manager'))
const Rooms = dynamicImport(() => import('@/features/chat-manager/rooms'))
const Calendar = dynamicImport(() => import('@/features/calender'))

// Utility Components
const Faqs = dynamicImport(() => import('@/app/faqs'))

const Tree = dynamicImport(() => import('@/app/school-tree'))

export {
	// Layout Components
	Layout,
	Panel,
	NotFound,
	ReqAdmin,

	// Auth Components
	Login,
	ResetPasswordPage,
	AccountManager,

	// Management Components
	ClassManager,
	UserManager,
	FacultyManager,
	ExpenseManager,
	StatManager,
	PromotionManager,

	// Student Related
	Admissions,
	StudentProfile,
	StudentRegistration,

	// Staff Related
	Teachers,
	TeacherRegistration,
	EmployeeRegistration,

	// File & Data Components
	Files,
	ChangeLog,
	Gallery,

	// Communication Tools
	Chat,
	Rooms,
	Mail,
	Calendar,

	// Utility Components
	Faqs,
	Tree,
}

export async function SessionLoader() {
	try {
		const data = await queryClient.fetchQuery({
			queryKey: [queryKeys.sessions],
			queryFn: getSession,
			staleTime: 1000 * 60 * 60, // 1 hour
		})
		return data || []
	} catch (error) {
		return null
	}
}

export async function UserLoader() {
	try {
		const data = await queryClient.fetchQuery({
			queryKey: [queryKeys.currentUser],
			queryFn: getCurrentUser,
			staleTime: 1000 * 60 * 60, // 1 hour
			retry: 1,
		})
		return data || []
	} catch (error) {
		return null
	}
}

export async function FeeCycleLoader() {
	try {
		const data = await queryClient.fetchQuery({
			queryKey: [queryKeys.billCycle],
			queryFn: getBillCycles,
			staleTime: 1000 * 60 * 60 * 2, // 1 hour
		})

		return data || []
	} catch (error) {
		return null
	}
}

export async function StudentLoader() {
	try {
		const data = await queryClient.fetchQuery<Student[]>({
			queryKey: [queryKeys.students],
			queryFn: getSortedStudents,

			staleTime: 1000 * 60 * 60 * 2, // 1 hour
		})
		return data || []
	} catch (error) {
		return null
	}
}

export async function Loaders() {
	const [session, user, billCycle] = await Promise.all([
		SessionLoader(),
		UserLoader(),
		FeeCycleLoader(),
	])
	return { session, user, billCycle }
}
