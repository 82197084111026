import { authActions } from '@/context/authContext'
import axios, {
	type AxiosError,
	type AxiosInstance,
	type AxiosRequestConfig,
	type AxiosResponse,
	type CancelTokenSource,
	isAxiosError,
} from 'axios'

import { httpConfig } from './urlMapper'

export const didAbort = (error: unknown): { aborted: boolean } | false =>
	axios.isCancel(error) ? { aborted: true } : false


const getCancelSource = (): CancelTokenSource => axios.CancelToken.source()


export const isApiError = (error: unknown): error is AxiosError =>
	axios.isAxiosError(error)

const handleError = (error: unknown) => {
	console.error('API Error:', error)
	if (isAxiosError(error)) {

		const serverMessage = error.response?.data?.message
		if (serverMessage) {
			throw new Error(serverMessage)
		}

		// Handle network errors
		if (error.request) {
			throw new Error(
				'Unable to reach the server. Please check your connection.',
			)
		}

		// Handle other axios errors
		throw new Error(error.message || 'An unexpected error occurred')
	}

	// Handle non-axios errors
	throw new Error('An unexpected error occurred')
}

const withAbort = <T>(fn: (...args: any[]) => Promise<AxiosResponse<T>>) => {
	const executor = async (...args: any[]): Promise<AxiosResponse<T>> => {
		const originalConfig: AxiosRequestConfig & {
			abort?: (cancel: () => void) => void
		} = args[args.length - 1]
		const { abort, ...config } = originalConfig

		if (typeof abort === 'function') {
			const { cancel, token } = getCancelSource()
			config.cancelToken = token
			abort(cancel)
		}

		try {
			if (args.length > 2) {
				const [url, body] = args
				return await fn(url, body, config)
			} else {
				const [url] = args
				return await fn(url, config)
			}
		} catch (error) {
			if (didAbort(error)) {
				throw new Error('Request cancelled')
			}
			return handleError(error)
		}
	}

	return executor
}
const instance = axios.create(httpConfig)
instance.interceptors.response.use(
	response => response,
	error => {
		if (error.response?.status === 401 || error.response?.status === 403) {
			console.warn('Authorization error:', error.response.status)
			localStorage.removeItem('authToken')
			authActions.logout()
			if (window.location.pathname !== '/') {
				window.location.href = '/'
			}

			return Promise.reject(error)
		}
		return Promise.reject(error)
	},
)
export default instance

const apiClient = (axiosInstance: AxiosInstance) => {
	return {
		get: <T>(url: string, config: AxiosRequestConfig = {}) =>
			withAbort<T>(axiosInstance.get)(url, config),

		delete: <T>(url: string, config: AxiosRequestConfig = {}) =>
			withAbort<T>(axiosInstance.delete)(url, config),

		post: <T>(url: string, body: any, config: AxiosRequestConfig = {}) =>
			withAbort<T>(axiosInstance.post)(url, body, config),

		patch: <T>(url: string, body: any, config: AxiosRequestConfig = {}) =>
			withAbort<T>(axiosInstance.patch)(url, body, config),

		put: <T>(url: string, body: any, config: AxiosRequestConfig = {}) =>
			withAbort<T>(axiosInstance.put)(url, body, config),
	}
}

export const api = apiClient(instance)
