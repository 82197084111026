import {
	EmployeeRegistration,
	ExpenseManager,
	StudentRegistration,
	TeacherRegistration,
} from '@/app'

import LazyLoad from '@/components/LazyLoad'

export const registrationRoutes = [
	{
		path: 'students',
		element: <LazyLoad component={StudentRegistration} />,
	},
	{
		path: 'employees',
		element: <LazyLoad component={EmployeeRegistration} />,
	},
	{
		path: 'teachers',
		element: <LazyLoad component={TeacherRegistration} />,
	},
	{
		path: 'expense',
		element: <LazyLoad component={ExpenseManager} />,
	},
]
