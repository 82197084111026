import type { RouteObject } from 'react-router-dom'

import {
	Admissions,
	ChangeLog,
	Chat,
	ClassManager,
	FacultyManager,
	Files,
	Gallery,
	Panel,
	PromotionManager,
	SessionLoader,
	StudentLoader,
	StudentProfile,
	Teachers,
	UserManager,
} from '@/app'

import LazyLoad from '@/components/LazyLoad'

export const dashboardRoutes: RouteObject[] = [
	{
		index: true,
		element: <LazyLoad component={Panel} />,
	},
	{
		path: 'admissions',
		element: <LazyLoad component={Admissions} />,
	},
	{
		path: 'faculty',
		element: <LazyLoad component={Teachers} />,
	},
	{
		path: 'classes',
		element: <LazyLoad component={ClassManager} />,
	},
	{
		path: 'student-profile/:id',
		element: <LazyLoad component={StudentProfile} />,
	},
	{
		path: 'files',
		element: <LazyLoad component={Files} />,
	},
	{
		path: 'chat',
		element: <LazyLoad component={Chat} />,
	},
	{
		path: 'gallery',
		element: <LazyLoad component={Gallery} />,
	},
	{
		path: 'users',
		loader: SessionLoader,
		element: <LazyLoad component={UserManager} />,
	},
	{
		path: 'change-log',
		element: <LazyLoad component={ChangeLog} />,
	},
	{
		path: 'faculty-manager',
		element: <LazyLoad component={FacultyManager} />,
	},
	{
		path: 'promotions',
		loader: StudentLoader,
		element: <LazyLoad component={PromotionManager} />,
	},
]
